import React, { Component, Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from './common/history'
import _ from 'lodash';
import $ from 'jquery';
import Loadable from 'react-loadable';
import Constants from './common/constants';
import { userManager, isLoggedIn } from './common/authentication'
import { makeAuthenticator, Callback } from 'react-oidc';
import { Http } from './common/common';
import { HubContextProvider } from './contexts/HubContext';
import { cacheSet, cacheGet, cacheDelete } from './common/cacheManager';
import { ActionType } from './common/typeConfig';
import { getLocalizedText, convertListToTranslation } from './common/localizationManager';
import { getFirstAuthorizedPageUrl, checkIfPageExistAndGranted } from './common/authorization';
import { ToastContainer, Bounce } from 'react-toastify';
import { Translations } from "./common/translations";
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

const loading = () => <div className="animated fadeIn pt-3 text-center">{getLocalizedText("LOADING")}</div>;

const REDIRECT_LOCATION_SESSION_KEY = 'redirect_location';

// Containers
const DefaultLayout = Loadable({
    loader: () => import('./containers/DefaultLayout'),
    loading
});

// Pages
const Login = Loadable({
    loader: () => import('./views/Routes/CommonPages/Login/Login'),
    loading
});

const Register = Loadable({
    loader: () => import('./views/Routes/CommonPages/Register/Register'),
    loading
});

const ForgotPassword = Loadable({
    loader: () => import('./views/Routes/CommonPages/ForgotPassword/ForgotPassword'),
    loading
});

const ResetPassword = Loadable({
    loader: () => import('./views/Routes/CommonPages/ResetPassword/ResetPassword'),
    loading
});

const ChangePassword = Loadable({
    loader: () => import('./views/Routes/CommonPages/ChangePassword/ChangePassword'),
    loading
});

const UserVerification = Loadable({
    loader: () => import('./views/Routes/CommonPages/UserVerification/UserVerification'),
    loading
});

const Page404 = Loadable({
    loader: () => import('./views/Routes/CommonPages/Page404/Page404'),
    loading
});

const Page500 = Loadable({
    loader: () => import('./views/Routes/CommonPages/Page500/Page500'),
    loading
});

const Page401 = Loadable({
    loader: () => import('./views/Routes/CommonPages/Page401/Page401'),
    loading
});

const AppWithAuth = makeAuthenticator({
    userManager: userManager,
    placeholderComponent: loading,
})((props) => <Route path="*" name="Home" render={routeProps => (
    <HubContextProvider>
        <DefaultLayout {...routeProps} />
    </HubContextProvider>)} />)

$(document).on('click', 'a[href="#"]', function (e) {
    e.preventDefault();
});

const toastContainerProps = {
    position: "bottom-right",
    transition: Bounce,
    autoClose: 4000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    get style() {
        return {
            'bottom-right': { bottom: 40 },
            'bottom-left': { bottom: 40 },
            'bottom-center': { bottom: 40 }
        }[this.position];
    }
}

String.prototype.format = function () {
    var formatted = this;
    for (var arg in arguments) {
        formatted = formatted.replace("{" + arg + "}", arguments[arg]);
    }
    return formatted;
};

class App extends Component {

    convertClaimsToJSON = (claims) => {
        var resp = {}

        for (var resource of claims.authResources) {
            if (resp[resource.resourceCode] == null)
                resp[resource.resourceCode] = {}

            resp[resource.resourceCode] = { ...resp[resource.resourceCode], ...resource };
            resp[resource.resourceCode].status = resource.status == 1;
            delete resp[resource.resourceCode].authActions;

            for (var action of resource.authActions) {
                resp[resource.resourceCode][ActionType[action.actionType]] = resp[resource.resourceCode].status
                    && resource.status == 1 && action.status == 1 && action.authUserRights.some(x => x.status == 1);
            }
        }
        console.log('perms', resp);
        return resp;
    }

    navigateToLoginIfUserNotLoggedIn = () => {
        return !isLoggedIn()
            ? <Route exact path="*" render={(props) => {
                this.saveRequestedLocation(props.location);
                userManager.signinRedirect()
                    .catch(e => {
                        console.error(e);
                        history.push("/login");
                    });
                return loading;
            }} />
            : null
    }

    navigateToChangePasswordIfForced = () => {
        let profile = cacheGet('profile');

        return isLoggedIn() && profile && profile.shouldChangePassword
            ? <Route exact path="*" name="Change Password" component={ChangePassword} />
            : null
    }

    saveRequestedLocation = (location) => {
        if (location && location.pathname != '/')
            cacheSet(REDIRECT_LOCATION_SESSION_KEY, location, 5, 'session');
    }

    getRequestedLocation = () => {
        const requestedLocation = cacheGet(REDIRECT_LOCATION_SESSION_KEY, 'session');
        cacheDelete(REDIRECT_LOCATION_SESSION_KEY, 'session');
        return requestedLocation;
    }

    render() {
        return (<>
            <ToastContainer {...toastContainerProps} />
            <Router history={history}>
                <Suspense fallback={loading}>
                    <Switch>
                        <Route
                            path="/callback"
                            render={routeProps => (
                                <>
                                    <div className="animated fadeIn pt-3 text-center">{getLocalizedText("LOADING")}</div>
                                    <Callback
                                        userManager={userManager}
                                        onSuccess={(user) => {

                                            // `user.state` will reflect the state that was passed in via signinArgs.
                                            console.warn(user);
                                            Http.post(`${Constants.IdentityURL}/Account/GetResources`, undefined, { timeout: 120000 })
                                                .then(response => { //Successful http response,
                                                    try {
                                                        var data = response.data.data;
                                                        var session = data.session;
                                                        var user = session.currentUser;
                                                        // set the authTemplate which is returned from request's response
                                                        var authTemplate = data.authTemplate;

                                                        cacheSet("profile", user, 600);
                                                        cacheSet("authorizationClaims", this.convertClaimsToJSON(session.authorizationClaims), 600);
                                                        cacheSet("translations", _.assign(Translations, convertListToTranslation(data.labels)), 600);
                                                        cacheSet("notifications", data.notifications.list, 600);

                                                        const requestedLocation = this.getRequestedLocation();
                                                        let requestedLocationHref;

                                                        if (requestedLocation && checkIfPageExistAndGranted(requestedLocation.pathname))
                                                            requestedLocationHref = requestedLocation.pathname + requestedLocation.search

                                                        // if there is a default page for the template then redirect to it
                                                        // else redirect to requested location if exists or first authorized one
                                                        window.location.href = cacheGet('profile').shouldChangePassword
                                                            ? checkIfPageExistAndGranted(Constants.CHANGE_PASSWORD_URL)
                                                            : (requestedLocationHref
                                                                || checkIfPageExistAndGranted(authTemplate.templateDefaultPage)
                                                                || getFirstAuthorizedPageUrl());
                                                    } catch (error) {
                                                        console.error(error);
                                                        routeProps.history.push("/logout")
                                                    }
                                                })
                                                .catch((e) => {
                                                    console.error(e);
                                                    routeProps.history.push("/logout")
                                                });
                                        }}
                                        onError={(e) => {
                                            console.error(e);
                                            routeProps.history.push("/login", { isLoginFailed: true })
                                        }}
                                    />
                                </>
                            )}
                        />
                        <Route exact path="/logout" name="Login Page" component={Login} />
                        <Route exact path="/login" name="Login Page" render={(props) => isLoggedIn() ? <Redirect to={getFirstAuthorizedPageUrl()} /> : <Login {...props} />} />
                        <Route exact path="/register" name="Register Page" component={Register} />
                        <Route exact path="/forgotPassword" name="Forgot Password" component={ForgotPassword} />
                        <Route exact path="/resetPassword/:unique" name="Reset Password" component={ResetPassword} />
                        <Route exact path="/userVerification/:unique" name="User Verification" component={UserVerification} />
                        <Route exact path="/401" name="Page 401" component={Page401} />
                        <Route exact path="/404" name="Page 404" component={Page404} />
                        <Route exact path="/500" name="Page 500" component={Page500} />

                        {this.navigateToLoginIfUserNotLoggedIn()}

                        {this.navigateToChangePasswordIfForced()}

                        <AppWithAuth />
                    </Switch>
                </Suspense>
            </Router>
        </>
        )
    }
}

export default App;